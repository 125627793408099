<template>
  <div class="w-full h-full pt-5 px-3">
    <template>
      <div class="flex flex-row justify-start gap-4">
        <h1 class="text-xl text-left font-extrabold">Confirmation</h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
      <div>
        <template v-if="hasModuleAccess">
          <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
            <loader size="xxs" :loader-image="false" />
          </div>
          <div v-else>
            <card class="p-5 mt-3">
              <div class="h-full w-full">
                <div class="mx-3 mt-6">
                  <Card :footer-has-border="false" class="mt-6 p-5">
                    <template v-slot:footer>
                      <card-footer
                        reloadcard
                        @print="print"
                        @reload="reload()"
                        @sortType="handleSort($event)"
                        @option="handleOptionSelect($event)"
                        @searchResult="handleSearch($event)"
                      />
                    </template>
                  </Card>
                </div>
                <div class="w-full">
                  <div
                    class="w-full h-full p-3 mb-8"
                    v-if="employees.length > 0"
                  >
                    <STable
                      :headers="confirmedEmployeeHeader"
                      :items="employees"
                      style="width: 100%"
                      :loading="loadingTable"
                      editable-column
                      @editable="resetHeaders($event)"
                      aria-label="employee table"
                      class="w-full"
                      id="printMe"
                      has-checkbox
                    >
                      <template v-slot:item="{ item }">
                        <div class="flex" v-if="item.fname">
                          <img
                            class=""
                            :src="item.data.photo"
                            v-if="item.data.photo"
                            alt="profile pic"
                            style="
                              height: 35px;
                              width: 35px;
                              border-radius: 5px;
                              object-fit: cover;
                            "
                          />
                          <div
                            style="
                              height: 35px;
                              width: 35px;
                              border-radius: 5px;
                            "
                            class="text-blueCrayola border text-center font-semibold pt-2"
                            v-else
                          >
                            {{
                              $getInitials(
                                `${item.data.fname} ${item.data.lname}`
                              )
                            }}
                          </div>
                          <div class="ml-2 text-sm">
                            <div
                              class="font-bold cursor-pointer"
                              style="color: #525252"
                            >
                              {{ item.data.fname }} {{ item.data.lname }}
                            </div>
                            <div class="text-romanSilver">
                              {{
                                !item.data.orgFunction
                                  ? "-"
                                  : item.data.orgFunction.name
                              }}
                            </div>
                          </div>
                        </div>
                        <span
                          v-else-if="item.resumptionDate"
                          class="text-center"
                          >{{
                            !item.data.resumptionDate
                              ? "-"
                              : `${$DATEFORMAT(
                                  new Date(item.data.resumptionDate),
                                  "MMMM dd, yyyy"
                                )}`
                          }}</span
                        >
                        <span
                          v-else-if="item.confirmationDueDate"
                          class="text-center"
                          >{{
                            !item.data.confirmationDueDate
                              ? "-"
                              : `${$DATEFORMAT(
                                  new Date(item.data.confirmationDueDate),
                                  "MMMM dd, yyyy"
                                )}`
                          }}</span
                        >
                        <span
                          v-else-if="item.employeePerformanceRatingScore"
                          class="text-center"
                        >
                          {{
                            !item.data.employeePerformanceRatingScore
                              ? "0"
                              : item.data.employeePerformanceRatingScore
                          }}
                        </span>
                        <span
                          v-else-if="item.employeeConfirmation"
                          class="text-center"
                        >
                          {{
                            item.data.employeeConfirmation
                              ? Capitalize(
                                  item.data.employeeConfirmation.recommendation
                                )
                              : "------"
                          }}
                        </span>
                        <div
                          v-else-if="item.employeeConfirmationStatus"
                          style="width: 100px"
                          class="p-3"
                          :class="{
                            pending:
                              item.data.employeeConfirmationStatus ===
                              'pending',
                            approved:
                              item.data.employeeConfirmationStatus ===
                                'approved' ||
                              item.data.employeeConfirmationStatus ===
                                'extended',
                            disapproved:
                              item.data.employeeConfirmationStatus ===
                                'disapproved' ||
                              item.data.employeeConfirmationStatus ===
                                'terminated',
                          }"
                        >
                          <div class="w-auto flex flex-col">
                            <p class="text-sm font-semibold">
                              {{
                                item.data.employeeConfirmationStatus
                                  ? Capitalize(
                                      item.data.employeeConfirmationStatus
                                    )
                                  : "------"
                              }}
                            </p>
                          </div>
                        </div>
                        <span v-else-if="item.id">
                          <Menu left top="-150" margin="24" class="my-2 p-0">
                            <template v-slot:title>
                              <icon icon-name="more_icon" size="xs" />
                            </template>
                            <div style="width: 240px; padding: 10px 5px">
                              <div
                                v-for="(option, index) in moreOptionsFunction(
                                  item.data
                                )"
                                :key="index"
                                @click="
                                  handleOptionClick(option.slug, item.data)
                                "
                              >
                                <div
                                  class="flex py-1 px-3 more h-8 cursor-pointer"
                                  v-if="option.mode === mode"
                                >
                                  <icon
                                    :icon-name="option.icon"
                                    :class-name="`text-${option.color} mr-2`"
                                    size="xms"
                                  />
                                  <p class="pt-1 text-sm">{{ option.title }}</p>
                                </div>
                              </div>
                            </div>
                          </Menu>
                        </span>
                      </template>
                    </STable>
                  </div>
                  <div class="w-full" v-else>
                    <div
                      class="w-full flex flex-col justify-center items-center my-28"
                    >
                      <icon
                        icon-name="empty_agreement"
                        size="l"
                        style="width: 300px"
                      />
                      <div class="w-1/4 text-base text-center">
                        There are no pending confirmation.
                      </div>
                    </div>
                  </div>
                </div>
                <disengage-employee
                  :open-modal="openDisengageModal"
                  :user-details="userDetails"
                  @close="(openDisengageModal = false), handleClose()"
                />
                <ApprovalProgress ref="approval_status" />
                <Modal v-if="dialogDel">
                  <Card class="p-5 flex flex-col max-w-md">
                    <div class>
                      <p class="font-bold ml-4 my-5 text-center">
                        Are you sure you want to delete this Employee?
                      </p>
                      <div class="flex">
                        <Button
                          class="text-white"
                          :background="
                            appearance.buttonColor || defaultBTNColor
                          "
                          @click="deleteEmployee"
                        >
                          Yes
                        </Button>
                        <Button
                          background-color="none"
                          class="text-dynamicBackBtn ml-4 border border-solid border-dynamicBackBtn"
                          @click="dialogDel = false"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Modal>
              </div>
            </card>
          </div>
        </template>
        <div v-else>
          <ErrorComponent />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import { mapState } from "vuex";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import Button from "@/components/Button";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "Confirmation",
  components: {
    STable,
    Card,
    Button,
    CardFooter,
    Breadcrumb,
    Menu,
    ErrorComponent,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
    DisengageEmployee: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/disengageEmployee"
      ),
  },
  data() {
    return {
      hasModuleAccess: false,
      openViewDetailsModal: false,
      openAssessmentModal: false,
      openConfirmModal: false,
      openExtensionModal: false,
      openDisengageModal: false,
      openApprovalModal: false,
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      settings: {},
      loading: true,
      loadingTable: false,
      isActive: null,
      dialogDel: false,
      sortType: null,
      userDetails: {},
      userId: "",
      confirmedEmployeeHeader: [
        { title: "EMPLOYEE NAME", value: "fname" },
        { title: "EMPLOYMENT DATE", value: "resumptionDate" },
        {
          title: "DUE DATE",
          value: "confirmationDueDate",
        },
        {
          title: "RATING (%)",
          value: "employeePerformanceRatingScore",
        },
        {
          title: "RECOMMENDATION",
          value: "employeeConfirmation",
        },
        { title: "STATUS", value: "employeeConfirmationStatus" },
        { title: "", value: "id", image: true },
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Employee",
          href: "",
          id: "Employee",
        },
        {
          disabled: false,
          text: "Confirmation",
          href: "confirmation",
          id: "confirmation",
        },
      ],
      moreOptions: [
        {
          title: "View Assesment",
          icon: "icon-eye",
          color: "chartPurple",
          slug: "view",
          mode: this.mode,
        },
        {
          title: "Confirmation Assessment",
          icon: "undo",
          color: "queenPink",
          slug: "assessment",
          mode: this.mode,
        },
        {
          title: "Confirm",
          icon: "icon-active",
          color: "green",
          slug: "confirm",
          mode: "admin",
        },
        {
          title: "Extend Probation",
          icon: "icon-upgrade",
          color: "green",
          slug: "extension",
          mode: "admin",
        },
        {
          title: "Disengage",
          icon: "icon-terminate",
          color: "desire",
          slug: "disengage",
          mode: "admin",
        },
        {
          title: "Approval Status",
          icon: "icon-upgrade",
          color: "green",
          slug: "approval",
          mode: this.mode,
        },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  computed: {
    ...mapState({
      employees: (state) => state.employee.probationEmployees,
      metaData: (state) => state.employee.metaData,
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    resetHeaders(headerObj) {
      this.confirmedEmployeeHeader = this.confirmedEmployeeHeader.map((obj) => {
        if (obj.value === headerObj.value) {
          return headerObj;
        }
        return obj;
      });
    },

    moreOptionsFunction(data) {
      const mustAssessEmployee = this.settings.confirmationMandatory;
      if (mustAssessEmployee) {
        if (data.employeeConfirmation === null) {
          return this.moreOptions.filter((item) => item.slug === "assessment");
        }
          if (
            data.employeeConfirmationStatus === "pending" ||
            data.employeeConfirmationStatus === "approved" ||
            data.employeeConfirmationStatus === "extended"
          ) {
            return this.moreOptions.filter(
              (item) => item.slug !== "assessment"
            );
          }
            return this.moreOptions.filter((item) => item.slug !== "assessment");

      } if (!mustAssessEmployee) {
        if (
          data.employeeConfirmationStatus === "pending" ||
          data.employeeConfirmationStatus === "approved" ||
          data.employeeConfirmationStatus === "extended"
        ) {
          return this.moreOptions.filter((item) => item.slug !== "assessment");
        }

        if (data.employeeConfirmation !== null) {
          return this.moreOptions.filter((item) => item.slug !== "assessment");
        }

          return this.moreOptions.filter((item) => item.slug !== "view");

      }

      return this.moreOptions;
    },

    async deleteEmployee() {
      try {
        await this.$_deleteEmployee(this.userDetails.userId);
        this.dialogDel = false;
        this.getEmployeeDetails();
        this.$toasted.success("Employee deleted successfully", {
          duration: 3000,
        });
      } catch (error) {
        this.$toasted.error("Employee can not be deleted", {
          duration: 3000,
        });
      }
    },
    queryUpdate() {
      this.loadingTable = true;
      const isActive = this.isActive ? `&isActive=${this.isActive}` : "";
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      if (this.mode === "admin") {
        this.$store
          .dispatch(
            "employee/getProbationEmployees",
            `${isActive}${sort}${search}${pageNumber}${itemPage}`
          )
          .then(() => {
            this.loadingTable = false;
          });
      } else {
        this.$store
          .dispatch(
            "employee/getManagerEmployees",
            `${isActive}${sort}${search}${pageNumber}${itemPage}`
          )
          .then(() => {
            this.loadingTable = false;
          });
      }
    },
    handleOptionSelect(option) {
      if (option === "Active") {
        this.isActive = true;
        return this.queryUpdate();
      }
      if (option === "Deactivated") {
        this.isActive = false;
        return this.queryUpdate();
      }
      this.isActive = null;
      return this.queryUpdate();
    },
    checkIfPresent(item, itemArray) {
      const itemIndex = itemArray.indexOf(item);
      if (itemIndex >= 0) {
        itemArray.splice(itemIndex, 1);
      } else {
        itemArray.push(item);
      }
      return itemArray;
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
    // eslint-disable-next-line consistent-return
    async handleOptionClick(slug, userDetails) {
      this.userDetails = userDetails;
      this.userId = userDetails.id;
      switch (slug) {
        case "view":
          try {
            if (this.mode === "admin") {
              await this.$handlePrivilege("confirmationHR", "viewDetails");
            }
            this.$router.push({
              name: `${
                this.mode === "admin" ? "ViewAssesment" : "EssViewAssesment"
              }`,
              params: { id: userDetails.id },
              query: { slug: this.mode, tab: "Assessment" },
            });
          } catch (error) {
            const message = "You do not have permission to perform this task";
            return this.$toasted.error(message, { duration: 3000 });
          }
          break;
        case "assessment":
          try {
            if (this.mode === "admin") {
              await this.$handlePrivilege(
                "confirmationHR",
                "performConfirmationAssesment"
              );
            }
            this.$router.push({
              name: `${
                this.mode === "admin" ? "CreateAssesment" : "EssCreateAssesment"
              }`,
              params: { id: userDetails.id },
              query: { slug: this.mode },
            });
          } catch (error) {
            const message = "You do not have permission to perform this task";
            return this.$toasted.error(message, { duration: 3000 });
          }
          break;
        case "confirm":
          try {
            if (this.mode === "admin") {
              await this.$handlePrivilege(
                "confirmationHR",
                "confirmEmployeeExceptionally"
              );
            }
            this.$router.push({
              name: `${
                this.mode === "admin"
                  ? "ConfirmAssesment"
                  : "EssConfirmAssesment"
              }`,
              params: { id: userDetails.id },
              query: { slug: this.mode, tab: "Payroll" },
            });
          } catch (error) {
            const message = "You do not have permission to perform this task";
            return this.$toasted.error(message, { duration: 3000 });
          }
          break;
        case "extension":
          try {
            if (this.mode === "admin") {
              await this.$handlePrivilege(
                "confirmationHR",
                "extendEmployeeProbation"
              );
            }
            this.$router.push({
              name: `${
                this.mode === "admin" ? "ExtendProbation" : "EssExtendProbation"
              }`,
              params: { id: userDetails.id },
              query: { slug: this.mode, tab: "Extend Probation" },
            });
          } catch (error) {
            const message = "You do not have permission to perform this task";
            return this.$toasted.error(message, { duration: 3000 });
          }
          break;
        case "disengage":
          try {
            await this.$handlePrivilege("confirmationHR", "disengageEmployee");
            this.openDisengageModal = true;
            return this.openDisengageModal;
          } catch (error) {
            const message = "You do not have permission to perform this task";
            return this.$toasted.error(message, { duration: 3000 });
          }
        case "approval":
          try {
            if (this.mode === "admin") {
              await this.$handlePrivilege(
                "confirmationHR",
                "viewApprovalStatus"
              );
            }
            if (!userDetails.employeeConfirmation) {
              const message = "Confirmation not yet submitted for Approval";
              return this.$toasted.info(message, { duration: 3000 });
            }
            return this.$refs.approval_status.toggle(
              userDetails.employeeConfirmation.id
            );
          } catch (error) {
            const message = "You do not have permission to perform this task";
            return this.$toasted.error(message, { duration: 3000 });
          }
        default:
      }
    },
    reload() {
      this.$store.dispatch("employee/get_Employees");
    },
    async getEmployeeDetails() {
      if (this.mode === "admin") {
        this.$store.dispatch("employee/getProbationEmployees", "").then(() => {
          this.getSettings();
        });
      } else {
        this.$store.dispatch("employee/getManagerEmployees", "").then(() => {
          this.getSettings();
        });
      }
    },
    getSettings() {
      this.$_getConfirmationSettings()
        .then((response) => {
          const {
            confirmationMandatory,
            criteria,
            defaultExtendPeriod,
            probationPeriod,
            ratings,
            exception,
          } = response.data.confirmationProbationRating;
          this.settings = {
            confirmationMandatory,
            criteria,
            defaultExtendPeriod,
            probationPeriod,
            ratings,
            exception,
          };
          this.loading = false;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
  },
  async created() {
    try {
      if (this.mode === "admin") {
        await this.$handlePrivilege("confirmationHR", "viewRegister");
      }
      this.hasModuleAccess = true;
      this.getEmployeeDetails();
    } catch (error) {
      this.hasModuleAccess = false;
    }
  },
};
</script>

<style scoped></style>
